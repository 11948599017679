const GTMDataLayer = ({ question, answer }) => {
  // GTM DataLayer
  // console.log("Question-" + question + ", Answer-" + answer);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "slideclick",
    question: question,
    answer: answer,
  });

  return <></>;
};

export default GTMDataLayer;
