import React,{useState} from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/NF_V1/main.scss";
import Footer from "../../Includes/Layouts/NF_V1/Footer";
import Header from "../../Includes/Layouts/NF_V1/Header";
import FormNF_V1 from "../../Forms/FormNF_V1";
import ProgressBar from "../../Includes/Layouts/NF_V1/ProgressBar";
import Testimonial from "../../Includes/Layouts/NF_V1/Testimonial";

const NF_V1 = () => {
  const [progressValue, setProgressValue] = useState('0');
  const redirectToTrustPilot = () => {
    window.open("https://uk.trustpilot.com/review/nationalfriendly.co.uk?utm_medium=trustbox&utm_source=Slider","_blank");
     
  };
  const progress = (value) =>{
     setProgressValue(value);
  }
  return (
    <>
    <AdtopiaLoadLP pageType="LP" splitName="NF_V1" />
    <div className="NF_V1">
      <div className="grey-bg">
      <div className="bg-image">
        <Header />
        <section className="formsec-bg pb-5">
          <div className="container">
              <h1 className="d-block d-sm-none d-md-none d-lg-none d-xl-none text-light text-center fw-bold">Compare Quotes</h1>
              <h2 className="d-block d-sm-none d-md-none d-lg-none d-xl-none text-light text-center fw-normal mb-0">Just a few pence a day!*.</h2>
              {progressValue == "0" ? 
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center d-xl-none d-lg-none d-sm-none d-md-none d-block">
                  <span class="start-but bg-white">Start<br/><img src="/assets/img/arrow-b.png" alt=""/></span>
              </div>:'' }
                <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 bg-white form-bg rounded my-3 p-3">
                  <div className="clearfix"></div>
                  <ProgressBar value={progressValue}></ProgressBar>
                  <FormNF_V1 progressValue={progressValue} progress={progress} />
                </div>
          </div>
        </section>
        <Testimonial onClick={redirectToTrustPilot}/>
        <Footer />
      </div>
      </div>
    </div>
  </>
  );
};

export default NF_V1;
