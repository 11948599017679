import React from 'react';
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from '../../../../Utility/FbViewPixel';
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 txl">
              <img className="logo" src="/assets/img/logo-white.png" alt="" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 txr">
              <AnchorAdv dataId="TOP_SITE_BANNER" className="btn heading_btn hvr-grow-rotate">
                See Benefits
                     <img src="/assets/AMCS_ADV_1/img/arrow.png" alt="" />
              </AnchorAdv>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default AdvHeader;
