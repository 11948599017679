import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BasicQuestionnaireOne from "../Includes/Layouts/NF_V2/BasicQuestionnaireOne";
import BasicQuestionnaireTwo from "../Includes/Layouts/NF_V2/BasicQuestionnaireTwo";
import BasicQuestionnaireFour from "../Includes/Layouts/NF_V2/BasicQuestionnaireFour";
import BasicQuestionaireCountry from "../Includes/Layouts/NF_V2/BasicQuestionaireCountry";
import DobDeatils from "../Includes/Layouts/NF_V2/DobDeatils";
import PostCode from "../Includes/Layouts/NF_V2/PostCode";
import PersonalDetails from "../Includes/Layouts/NF_V2/PersonalDetails";
import ContactDetails from "../Includes/Layouts/NF_V2/ContactDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import PrivacyPolicy from "../Includes/Layouts/NF_V2/PrivacyPolicy";
import * as EnvConstants from "../../Constants/EnvConstants";

const initialState = {
  showSlide1: "show",
  showSlide1_2: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide4_1: "hide",
  showSlide4_2: "hide",
  showSlide4_3: "hide",
  showSlide5: "hide",
  showSlide5_2: "hide",
  showSlide5_3: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
};
var houseowner = 0;
var country = 0;
var subheadline = 0;
var homeowner = 0;
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "question_1") {
        return {
          ...state,
          // showSlide1: "hide",
          // showSlide2: "show",
          showSlide1: "hide",
          showSlide1_2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question1_2") {
        country = action.payload.clickedSlide.slidevalue;
        return {
          ...state,
          showSlide1_2: "hide",
          showSlide2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question_2") {
        return {
          ...state,
          showSlide2: "hide",
          showSlide4: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question_3") {
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question_4") {
        homeowner = action.payload.clickedSlide.slidevalue;
        if (homeowner == 12) {
          houseowner  = 1;
          subheadline =
          "What constitutes value in your estate? Equity in your Home, Cash, Building Society Accounts, Stocks, Shares, Investments, Pensions, Insurance Policies and Personal Possessions including Watches, Jewelry and Cars etc..";
        } else {
          houseowner  = 0;
          subheadline =
          "What constitutes value in your estate? Cash, Building Society Accounts, Stocks, Shares, Investments, Pensions, Insurance Policies and Personal Possessions including Watches, Jewelry and Cars etc..";
        }
        return {
          ...state,
          showSlide4: "hide",
          showSlide5_2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question_7") {
        return {
          ...state,
          showSlide4_1: "hide",
          showSlide4_2: "show",
        };
      }else if (action.payload.clickedSlide.slide == "question_9") {
        return {
          ...state,
          showSlide4_2: "hide",
          showSlide4_3: "show",
        };
      }else if (action.payload.clickedSlide.slide == "question_10") {
        return {
          ...state,
          showSlide4_3: "hide",
          showSlide5_2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "Question_5") {
        return {
          ...state,
          showSlide5_2: "hide",
          showSlide5: "show",
        };
      } else if (action.payload.clickedSlide.slide == "Question_6") {
        return {
          ...state,
          showSlide5: "hide",
          showSlide5_3: "show",
        };
      } else if (action.payload.clickedSlide.slide == "postcode-next") {
        return {
          ...state,
          showSlide5_3: "hide",
          showSlide6: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "previousSlides": {
      if (action.payload.previousSlide.slide == "back01") {
        return {
          ...state,
          showSlide1_2: "show",
          showSlide2: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back01_2") {
        return {
          ...state,
          showSlide1: "show",
          showSlide1_2: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back03") {
        return {
          ...state,
          showSlide2: "show",
          showSlide4: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back04_1") {
        return {
          ...state,
          showSlide4: "show",
          showSlide4_1: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back4_2") {
        return {
          ...state,
          showSlide4_1: "show",
          showSlide4_2: "hide",
        };
      }else if (action.payload.previousSlide.slide == "back4_3") {
        return {
          ...state,
          showSlide4_2: "show",
          showSlide4_3: "hide",
        };
      }else if (action.payload.previousSlide.slide == "back04") {
        return {
          ...state,
          showSlide5_2: "show",
          showSlide5: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back05") {
        return {
          ...state,
          showSlide4: "show",
          showSlide5_2: "hide",
        };
      } else if (action.payload.previousSlide.slide == "postcode-back") {
        return {
          ...state,
          showSlide5: "show",
          showSlide5_3: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back06") {
        return {
          ...state,
          showSlide5_3: "show",
          showSlide6: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};

const FormNF_V2 = (props) => {
  const {
    register,
    errors,
    trigger,
    setError,
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [showPrivacy, setShowPrivacy] = useState("hide");


  const splitForm = useRef(null);
  // ******************************Slide Change***************************************
  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    if (formData.address1 !== "") {
      const txtStreet = form["txtStreet"].value;
      formData.txtStreet = txtStreet;
    }
    formData.page_name = "NF_V2";
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "NF_V2",
        queryString,
        visitorParameters.data
      );
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppConversionPixel === "true") {
              history.push("/fbpixel?split_name=NF_V2"); // fb fixel firing
            } else {
              history.push(
                "/thankyou?uuid=" +
                  visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            history.push(
              "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          history.push(
            "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };
  // ******************************Slide Chnage***************************************
  const slideChange = (e) => {
    let slide_name = e.target.name;
    if(slide_name == 'postcode-next'){
      window.scrollTo(0, 0);
    }else{
      window.scrollTo(0, 250);
    }
    let preVal = 0;
    if (props.progressValue < 18) {
      preVal = Number(props.progressValue) + 18;
    } else if (props.progressValue >= 18) {
      if (props.progressValue == 18) {
        localStorage.setItem("wills_question", e.target.value);
      }
      if (props.progressValue == 36) {
        let wills = localStorage.getItem("wills_question");
        if (e.target.value == 12 && wills == 15) {
          preVal = Number(props.progressValue) + 9;
        } else if (wills == 16) {
          preVal = Number(props.progressValue) + 9;
        } else {
          preVal = Number(props.progressValue) + 9;
        }

        localStorage.setItem("conditional_question", e.target.value);
      } else {
        preVal = Number(props.progressValue) + 9;
      }
    }
    props.progress(preVal);
    if (e.target.name == "question_4" || e.target.name == "question1_2") {
      setClickedSlide({ slide: e.target.name, slidevalue: e.target.value });
    } else {
      setClickedSlide({ slide: e.target.name });
    }
  };
  const slideChangeAddress = () => {
    window.scrollTo(0, 250);
    setClickedSlide({ slide: "address" });
  };
  const previousSlideChange = (e) => {
    window.scrollTo(0, 250);
    let preVal = 0;
    if (props.progressValue <= 18) {
      preVal = Number(props.progressValue) - 18;
    } else if (props.progressValue > 18) {
      if (props.progressValue == 36 || props.progressValue == 27) {
        preVal = Number(props.progressValue) - 9;
        localStorage.setItem("conditional_question", e.target.value);
      } else {
        let wills = localStorage.getItem("wills_question");
        let condtionQstn = localStorage.getItem("conditional_question");
        if (wills == 16 && props.progressValue == 54) {
          preVal = Number(props.progressValue) - 9;
        }
        if (condtionQstn == 12 && props.progressValue == 54 && wills == 15) {
          preVal = Number(props.progressValue) - 9;
        }
        if (condtionQstn == 12 && props.progressValue != 54) {
          preVal = Number(props.progressValue) - 9;
        }
        if (condtionQstn == 13 && props.progressValue != 54) {
          preVal = Number(props.progressValue) - 9;
        }
        if (condtionQstn == 13 && props.progressValue == 54) {
          preVal = Number(props.progressValue) - 9;
        }
      }
    }
    props.progress(preVal);
    setPreviousSlide({ slide: e.target.getAttribute("class") });
  };
  useEffect(() => {
    if (clickedSlide) {
      console.log(clickedSlide.slide);
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);
   const modalClick = (clickValue) => {
      switch (clickValue) {
        case 'showPrivacy': {
          setShowPrivacy('show');
          break;
        }
        case 'exitPrivacy': {      
          setShowPrivacy('hide');
          break;
        }
      }
    }; 
  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <BasicQuestionnaireOne
          className={`col-xl-8 text-center mid ${state.showSlide1}`}
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          value="0"
        />
        <BasicQuestionaireCountry
          className={`col-xl-8 text-center mid ${state.showSlide1_2}`}
          validation={register}
          slideChange={slideChange}
          backClick={previousSlideChange}
          getValues={getValues}
          value="18"
        />

        <BasicQuestionnaireTwo
          className={`col-xl-8 text-center mid ${state.showSlide2}`}
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          backClick={previousSlideChange}
          value="25"
        />

        <BasicQuestionnaireFour
          className={`col-xl-8 text-center mid ${state.showSlide4}`}
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          backClick={previousSlideChange}
          value="40"
        />
        <DobDeatils
          className={`col-xl-8 text-center mid ${state.showSlide5_2}`}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          getValues={getValues}
          splitForm={splitForm}
          slideChange={slideChange}
          backClick={previousSlideChange}
          value="55"
        />
        <PersonalDetails
          className={`col-xl-8 text-center mid ${state.showSlide5}`}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          getValues={getValues}
          splitForm={splitForm}
          slideChange={slideChange}
          slideChangeAddress={slideChangeAddress}
          backClick={previousSlideChange}
          setError={setError}
          subheadline={subheadline}
          value="70"
        />
        <PostCode
          className={`col-xl-8 text-center mid ${state.showSlide5_3}`}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          splitForm={splitForm}
          slideChange={slideChange}
          setError={setError}
          getValues={getValues}
          clearErrors={clearErrors}
          backClick={previousSlideChange}
          value="85"
        />
        <ContactDetails
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          className={`col-xl-8 text-center mid ${state.showSlide6}`}
          getValues={getValues}
          formSubmit={formSubmit}
          backClick={previousSlideChange}
          value="100"
        />
      </form>
      <PrivacyPolicy Value={showPrivacy} modalClick={modalClick}/>
    </>
  );
};
export default FormNF_V2;
