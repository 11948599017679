import React from "react";

const ProgressBar = ({ value }) => {
  const percentageValue = (value == '0') ? 'START' : value + "%";
  const content = (value == '0%') ? '' : 'completed';
  if (value == '0') {
    return (
      <>
      <h1 className="fw-bold d-none d-sm-block d-md-block d-lg-block d-xl-block text-center">Get my quote</h1>
      <h4 className="fw-normal mt-2 d-none d-sm-block d-md-block d-lg-block d-xl-block text-center">Find out how little it could cost to protect yourself and your family by taking out a Guaranteed Life Assurance Plan when you need it.</h4>
      </>
    );
  } else {
    return (
      <>
      <h1 className="fw-bold d-none d-sm-block d-md-block d-lg-block d-xl-block text-center">Get my quote</h1>
      <h4 className="fw-normal mt-2 d-none d-sm-block d-md-block d-lg-block d-xl-block text-center">Find out how little it could cost to protect yourself and your family by taking out a Guaranteed Life Assurance Plan when you need it.</h4>
      <div className="col-lg-12 progrss mt-3 px-3 pt-3">
          <div className="progress">
              <div className="progress-bar" id="progressBar" style={{width : percentageValue}}></div>
          </div>
          <p className="text-center prgrs_txt" id="progressBar_content">Progress {value}%</p>
      </div>
      </>
    );
  }
};
export default ProgressBar;
