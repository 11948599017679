import React, { useState } from "react";

const PrivacyPolicy = () => {
  const [showPrivacy, setShowPrivacy] = useState("hide");
  const modalClick = (clickValue) => {
    switch (clickValue) {
      case "showPrivacy": {
        setShowPrivacy("show");
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy("hide");
        break;
      }
    }
  };
  return (
    <>
      <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 text-center">
        <div className="safe-text my-3">
          <img src="/assets/img/ico-safe.png" alt="" />
          Safe | Secure | Privacy Protected
        </div>
        <p className="privacy-text">
          By submitting your enquiry, you agree to our{" "}
          <a
            className="privacyClass"
            onClick={() => {
              modalClick("showPrivacy");
            }}
          >
             General Privacy Notice 
          </a>{" "}
          and allow National Friendly to contact you about your Guaranteed Life Assurance Plan Quote.
        </p>
      </div>
      <div className={`modal fade privacy-modal ${showPrivacy}`} id="prvcy">
        <div className="modal-dialog modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title">Privacy Policy</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  modalClick("exitPrivacy");
                }}
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="modal-heading">
                We’re making it easier for you to find out how we handle your
                information
              </h5>
              <p className="modal-p">
                On the 25th May 2018 the General Data Protection Regulation,
                known as GDPR, came into effect. It gives you more control over
                how your data is used and how you’re contacted. The changes will
                also help to protect your personal data.
              </p>
              <p  className="modal-p">
                For you to find out what these new changes mean, we’ve created a
                new Privacy Notice which includes details about:
              </p>
              <ul className="prvcy_img modal-p">
                <li>
                  Your rights relating to the information we hold about you
                </li>
                <li>How we keep your personal information safe</li>
                <li>The types of personal information we collect and use</li>
                <li>The legal basis we rely on to use your information</li>
              </ul>
              <a
                href="/assets/NF_V1/media/general-privacy-notice-jul22.pdf"
                target="_blank"
                className="btn btn-download2 btn-block mb-3 modal-p"
              >
                General Privacy Notice
              </a>
              <p className="modal-p">
                The General Privacy Notice is for those policyholders with
                either a Tax-Exempt Savings Plan, With-Profits Bond, Guaranteed
                Life Assurance Plan or one of our legacy policies including Care
                Plus, Pensions, Unit Linked Plans, National Ambulance, Permanent
                Care, Dental & Optical, ISAs, Term Assurance and Custom and
                Benefits Fund policies.
              </p>

              <a
                href="/assets/NF_V1/media/pmi-privacy-notice-jul22.pdf"
                target="_blank"
                className="btn btn-download2 btn-block mb-3"
              >
                PMI Privacy Notice
              </a>
              <p className="modal-p">
                The PMI Privacy Notice is for those policyholders with either an
                Optimum, Your Health Fund, Healthcare or Healthguard policy.
              </p>

              <a
                href="/assets/NF_V1/media/protection-privacy-notice-jul22.pdf"
                target="_blank"
                className="btn btn-download2 btn-block mb-3"
              >
                Protection Privacy Notice
              </a>
              <p className="modal-p">
                The Protection Privacy Notice is for those policyholders with a
                Health Cover, Right to Health Cover or THIS Health Cover policy.
              </p>

              <a
                href="/assets/NF_V1/media/long-term-care-insurance-privacy-notice-jul22.pdf"
                target="_blank"
                className="btn btn-download2 btn-block mb-3"
              >
                Long Term Care Insurance Privacy Notice
              </a>
              <p className="modal-p">
                The Long–term Care Insurance Privacy Notice is for those
                policyholders with an Immediate Care Plan, a Deferred Care Plan
                or an Assisted Living Insurance policy.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  modalClick("exitPrivacy");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
