import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import GTMDataLayer from "../../Layouts/GTMDataLayer";

const BasicQuestionaireOne = ({
  className,
  slideChange,
  validation
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {

    GTMDataLayer({
      question: 'Were you born before 1972?', 
      answer: e.target.title
    });

    if (e.target.value == 1) {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value == 2) {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide1" className={`${className}`}>
        <div className="row m-0">
          <div className="slide-1">
                <h6 className="fw-bold mt-3">
                  Were you born before 1972?
                </h6>
                <RadioButton
                  value="1"
                  name="question_1"
                  className="radio"
                  labelName="Yes"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionOne}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="2"
                  name="question_1"
                  className="radio"
                  labelName="No"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionTwo}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionaireOne;
