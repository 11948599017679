import React from 'react';
import { DobYear, DobMonth, DobDay } from '../Constants/Constants';
import SelectBox from '../Components/UI/SelectBox';


const DateOfBirth = ({ textDob, validation, validationMsg,clearErrors}) => {
    const datevalidate= (e)=>{
        if (e.target.value == "")
        {
          document.getElementById("validate"+e.target.id).classList.add('date-error');
          document.getElementById("validate"+e.target.id).classList.remove('success');
        }
        else{
            clearErrors(e.target.id);
            document.getElementById("validate"+e.target.id).classList.add('success');
            document.getElementById("validate"+e.target.id).classList.remove('date-error');
        }

    }
    return (
      <>
        <div class="col-xl-4 col-lg-4 mb-3">
            <SelectBox
                className="form-select fw-normal"
                OptionValue={DobMonth}
                name="DobMonth"
                id="DobMonth"
                myRef={validation({
                    required: "Please Select Month"
                })}
                onChange={datevalidate}
                // validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
            />
              <i className="validate"  id="validateDobMonth"></i>
              {validationMsg.DobMonth && (
              <span
                className="error_msg "
                id="dobMonth_err"
              >
                {validationMsg.DobMonth.message}
              </span>
            )}

            {/* <span id="dobMonth_err" className="error_msg"></span> */}
        </div>
        <div class="col-xl-4 col-lg-4 mb-3">
          <SelectBox
              className="form-select fw-normal"
              OptionValue={DobDay}
              name="DobDay"
              id="DobDay"
              myRef={validation({
                  required: "Please Select Date"
              })}
              onChange={datevalidate}
              // validationMsg={validationMsg.DobDay && validationMsg.DobDay.message}
          />
          {/* <i className="tick fa" >jjjjjj</i> */}
            <i className="validate secondcheck"  id="validateDobDay"></i>
            {validationMsg.DobDay && (
            <span
              className="error_msg "
              id="dobDay_err"
            >
              {validationMsg.DobDay.message}
            </span>
          )}
            {/* {ZDobDay && (
            <i className="validate "  id="validateDobDay"></i>
          )} */}
        </div>
        <div class="col-xl-4 col-lg-4 mb-3">
          <SelectBox
              className="form-select fw-normal"
              OptionValue={DobYear}
              name="DobYear"
              id="DobYear"
              myRef={validation({
                  required: "Please Select Year"
              })}
              onChange={datevalidate}
              // validationMsg={validationMsg.DobYear && validationMsg.DobYear.message}
          />
          <i className="validate "  id="validateDobYear"></i>
          {validationMsg.DobYear && (
            <span
              className="error_msg "
              id="dobDay_err"
            >
              {validationMsg.DobYear.message}
            </span>
          )}
          {/* <span id="dobYear_err" className="error_msg"></span> */}
        </div>
        <span id="dob_final_err" className="error_msg"></span>
    </>
    )
}


export { DateOfBirth };