import React,{useState} from "react";
import PrivacyPolicy from "../../../Includes/Layouts/NF_V1/PrivacyPolicy";

const Footer = () =>{
   const [showPrivacy, setShowPrivacy] = useState("hide");
   const [showTerms, setShowTerms] = useState("hide");  
   const modalClick = (clickValue) => {
      switch (clickValue) {
      
        case 'showPrivacy': {
          setShowPrivacy('show');
          break;
        }
        case 'exitPrivacy': {      
          setShowPrivacy('hide');
          break;
        }
        case 'showTerms': {
          setShowTerms('show');
          break;
        }
        case 'exitTerms': {
          setShowTerms('hide');
          break
        }
      }
    };  
	return(
		<>
          <footer className="bg-white pt-5">
            <div className="container">
               <div className="col-xl-12 col-lg-12 text-center">
                     <img src="/assets/NF_V1/img/logo.png" alt=""/>
                     <p className="mt-4">
                        National Friendly is a trading name of National Deposit Friendly Society Limited. Registered office: 11-12 Queen Square, Bristol BS1 4NT. Registered in England and Wales no. 369F. National Deposit Friendly Society Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Our Financial Services Register number is 110008. You can check this at: https://register.fca.org.uk. National Deposit Friendly Society Limited is covered by the Financial Services Compensation Scheme and Financial Ombudsman Service.
                     </p>
                     <p>
                        Copyright © National-friendly. 2022 | All Rights Reserved
                     </p>
                     <p>
                        national-friendly.co.uk
                     </p>
               </div>
            </div>
            <ul className="mb-0 text-center text-light">
            <li>
               <a  onClick={() =>{ modalClick("showPrivacy");document.body.classList.add("modal-open");}}>Privacy Policy</a>
            </li>
            <li>
               <a data-bs-toggle="modal"  onClick={() =>{ modalClick("showTerms") }}>Terms and Conditions</a>
            </li>
            </ul>
         </footer>
         <div className={`modal fade privacy-modal ${showPrivacy}`} id="prvcy">
            <div className="modal-dialog modal-xl">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title">Privacy Policy</h5>
                        <button type="button" className="btn-close" onClick={()=>{modalClick('exitPrivacy') }}></button>
                    </div>
                    <div className="modal-body">
                        <h5>We’re making it easier for you to find out how we handle your information</h5>
                        <p>On the 25th May 2018 the General Data Protection Regulation, known as GDPR, came into effect. It gives you more control over how your data is used and how you’re contacted. The changes will also help to protect your personal data.</p>
                        <p>For you to find out what these new changes mean, we’ve created a new Privacy Notice
                            which includes details about:</p>
                        <ul className="prvcy_img">
                            <li>Your rights relating to the information we hold about you</li>
                            <li>How we keep your personal information safe</li>
                            <li>The types of personal information we collect and use</li>
                            <li>The legal basis we rely on to use your information</li>
                        </ul>
                        <a href="/assets/NF_V1/media/general-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            General Privacy Notice
                        </a>
                        <p>The General Privacy Notice is for those policyholders with either a Tax-Exempt Savings Plan, 
                            With-Profits Bond, Guaranteed Life Assurance Plan or one of our legacy policies including 
                            Care Plus, Pensions, Unit Linked Plans, National Ambulance, Permanent Care, Dental & Optical, 
                            ISAs, Term Assurance and Custom and Benefits Fund policies.
                        </p>

                        <a href="/assets/NF_V1/media/pmi-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            PMI Privacy Notice
                        </a>
                        <p>The PMI Privacy Notice is for those policyholders with either an Optimum, Your Health Fund, Healthcare or 
                            Healthguard policy.</p>

                        <a href="/assets/NF_V1/media/protection-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            Protection Privacy Notice
                        </a>
                        <p>The Protection Privacy Notice is for those policyholders with a Health Cover, Right to Health Cover or 
                            THIS Health Cover policy.</p>
                        
                        <a href="/assets/NF_V1/media/long-term-care-insurance-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            Long Term Care Insurance Privacy Notice
                        </a>
                        <p>The Long–term Care Insurance Privacy Notice is for those policyholders with an Immediate Care Plan, a Deferred Care Plan or an 
                            Assisted Living Insurance policy.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={()=>{modalClick('exitPrivacy') }} >Close</button>
                    </div>
                </div>
            </div>
        </div>
         {/* <PrivacyPolicy Value={showPrivacy} modalClick={modalClick}/> */}
        <div className={`modal fade privacy-modal ${showTerms}`} id="terms">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Terms & Conditions</h5>
                        <button type="button" className="btn-close" onClick={()=>{modalClick('exitTerms') }}></button>
                    </div>
                    <div className="modal-body">
                        <p>These terms apply to your use of our website. You should check you’re happy to agree
                            to them before using this website and ask us to explain anything you’re unsure about.</p>
                            <h5>Definitions</h5>
                            <ul>
                                <li className="mm"><b>Terms: </b>means these website terms of use</li>
                                <li><b>We/Us/Our:  </b>means National Friendly</li>
                                <li><b>You:  </b>means the user of the website</li>
                            </ul>
                            <h5>Content, copyright & trademarks</h5>
                            <p>This site is designed to explain who we are and what services we provide.</p>
                            <p>We may need at any time to change or remove, temporarily or permanently, the website or any part of it without notice.</p>
                            <p>All copyright, trademarks and all other intellectual property rights in all material or content provided as part of the website belong to us or our partners and no permission is given in respect of their use.</p>
                            <p>You may download, store and print the content on this site for your personal use only. You are not permitted to publish, re-transmit, re-distribute or otherwise reproduce any of the content that appears on any page of this site, and are prohibited from using content for a commercial or illegal purpose.</p>
                            <h5>Third party links</h5>
                            <p>We provide third party links to other websites for your convenience, but as we do not own or control them we cannot be responsible for their availability or functionality.</p>
                            <h5>Governing law</h5>
                            <p>These terms shall be governed by and construed in accordance with English law and the English courts shall have jurisdiction over any disputes.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary"  onClick={()=>{modalClick('exitTerms') }}>Close</button>
                    </div>
                </div>
               </div> 
        </div>
        </>
	)
}

export default Footer;