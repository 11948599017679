import React from "react";
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header className="bg-white py-2 d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="container">
              <div className="col-xl-12 col-lg-12 text-center">
                  <img src="/assets/img/logo.png" alt=""/>
              </div>
          </div>
      </header>
    </>
  );
};

export default Header;
