import React,{useState} from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/NF_V2/main.scss";
import Footer from "../../Includes/Layouts/NF_V2/Footer";
import Header from "../../Includes/Layouts/NF_V2/Header";
import ProgressBar from "../../Includes/Layouts/NF_V2/ProgressBar";
import Testimonial from "../../Includes/Layouts/NF_V2/Testimonial";
import FormNF_V2 from "../../Forms/FormNF_V2";

const NF_V2 = () => {
  const [progressValue, setProgressValue] = useState('0');
  const redirectToTrustPilot = () => {
    window.open("https://uk.trustpilot.com/review/nationalfriendly.co.uk?utm_medium=trustbox&utm_source=Slider","_blank");
     
  };
  const progress = (value) =>{
     setProgressValue(value);
  }
  return (
    <>
    <AdtopiaLoadLP pageType="LP" splitName="NF_V2" />
    <div className="NF_V2">
      <div className="grey-bg">
      <div className="bg-image">
        <Header />
        <section className="formsec-bg pb-5">
          <div className="container">
              <h1 className="d-block d-sm-none d-md-none d-lg-none d-xl-none text-light text-center fw-bold">Get my quote</h1>
              <h2 className="d-block d-sm-none d-md-none d-lg-none d-xl-none text-light text-center fw-normal mb-0">Find out how little it could cost to protect yourself and your family by taking out a Guaranteed Life Assurance Plan when you need it.</h2>
              {progressValue == "0" ? 
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center d-xl-none d-lg-none d-sm-none d-md-none d-block">
                  <span class="start-but bg-white">Start<br/><img src="/assets/img/arrow-b.png" alt=""/></span>
              </div>:'' }
                <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10 bg-white form-bg rounded my-3 p-3">
                  <div className="clearfix"></div>
                  <ProgressBar value={progressValue}></ProgressBar>
                  <FormNF_V2 progressValue={progressValue} progress={progress} />
                </div>
          </div>
        </section>

        <section class=" pt-5 guaranteed-assurance">
   <div class="container">
     <div class="col-xl-12 col-lg-12 text-center">
     <h4>Guaranteed Life Assurance</h4>

<ul>
  <li>A ‘whole of life’ insurance policy which pays out a cash lump sum when you die</li>
  <li>Leaving behind a lump sum could make a big difference to your loved ones</li>
  <li>Acceptance is guaranteed, no medical exam required</li>
  <li>Flexible monthly premium payments</li>
</ul>
     </div>
   </div>

 </section>


        <Testimonial onClick={redirectToTrustPilot}/>
        <Footer />
      </div>
      </div>
    </div>
  </>
  );
};

export default NF_V2;
