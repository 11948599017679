import React, { useContext, useState } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/ProgressBar";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  formSubmit,
  value,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const validateSlide = async (e) => {
    e.preventDefault();
    const values = getValues();
    var phoneVal = await trigger("txtPhone");
    var emailVal;
    if (phoneVal) {
      emailVal = await trigger("txtEmail");
    } else {
      return false;
    }
    if (emailVal && phoneVal) {
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        formSubmit();
      }
    }
  };
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    const email = values["txtEmail"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    const phone = values["txtPhone"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Email Already Exist",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

    const validNumber = (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
            return false;
        }
        else {
            return true;
        }
    }
  return (
    <>
      <div id="slide7" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
          <div className="slide-11">
          <h6 class="fw-bold mt-3">Contact Number</h6>
            <div class="input-group mb-3">
                  <Telephone
                    name="txtPhone"
                    className="form-control"
                    placeholder="Contact Number"
                    maxlength="11"
                    onKeyPress={validNumber}
                    onBlur={phoneValidCheck}
                    validation={validation({
                      pattern: {
                        value: /^[0-9]*$/i,
                        message: "Please Enter Valid Phone Number",
                      },
                      required: "Please Enter Valid Phone Number",
                    })}
                    validationMsg={
                      validationMsg.txtPhone && validationMsg.txtPhone.message
                    }
                  ></Telephone>
                    <span className="input-group-text">
                      <img
                        src="/assets/img/privacy.png"
                        alt=""
                      />
                    </span>
                {validationMsg.txtPhone && (
                  <span className="error_msg " id="phone_err">
                    {validationMsg.txtPhone.message}
                  </span>
                )}
            </div>
            <h6 class="fw-bold mt-3">Email Address</h6>
                <div class="input-group mb-3">
                  <Email
                    name="txtEmail"
                    className="form-control privacy"
                    placeholder="Email Address"
                    onBlur={emailValidCheck}
                    validation={validation({
                      required: "Please Enter Valid Email Address",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtEmail && validationMsg.txtEmail.message
                    }
                  ></Email>
                    <span className="input-group-text">
                      <img
                        src="/assets/img/privacy.png"
                        alt=""
                      />
                    </span>
                {validationMsg.txtEmail && (
                  <span className="error_msg " id="phone_err">
                    {validationMsg.txtEmail.message}
                  </span>
                )}
                </div>
              <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
                <InputButton
                  name="formSubmitBtn"
                  className="btn continue-btn fw-bold w-100  py-3 text-light"
                  id="formSubmitBtn"
                  value="Get My Free Quote &gt;&gt;"
                  btnType="submit"
                  style={{ color: "#fff" }}
                  disabled={disabled}
                  onClick={validateSlide}
                />
               </div>
                <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
                  <Anchor
                    id="back06"
                    className="back06"
                    children="<< Previous"
                    onClick={backClick}
                  >
                    <img src="/assets/img/arrow.png" alt="" />{" "}
                    Previous
                  </Anchor>
                </div>
            </div>
          </div>
    </>
  );
};
export default ContactDetails;
