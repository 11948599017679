import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import { Salutation , swear } from "../../../../Constants/Constants";
import GTMDataLayer from "../GTMDataLayer";

const PersonalDetails = ({
  validation,
  validationMsg,
  className,
  trigger,
  getValues,
  slideChange,
  splitForm,
  backClick,
  value,
  setError
}) => {
  const [disable,setDisable]=useState(false);
  const [fNameError,setFNameError]=useState(false);
  const [lNameError,setLNameError]=useState(false);
  let [text , setText] = React.useState('')
  const validateSlide = async (e) => {
    e.preventDefault();
    if(fNameError == true || lNameError == true) {
      setDisable(true);
      return false;
    }
    let errorFlag = 0;
    const values = getValues();
    let lstSalutationResult = await trigger("lstSalutation");
    let txtFNameResult;
    let txtLNameResult;
    if(lstSalutationResult)
    {
       txtFNameResult = await trigger("txtFName");
    }
    else{
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      return false;
    }

    if (!lstSalutationResult || !txtFNameResult || !txtLNameResult) {
      errorFlag = 1;
    }

    if (errorFlag === 0) {
      GTMDataLayer({
        question: 'Personal Details', 
        answer: 'completed'
      });
      slideChange(e);
    }
  };

  const FbogusCheck = (e) =>{
    const foundSwears = swear.filter(word => e.target.value.toLowerCase().includes(word.toLowerCase()));
    if(foundSwears.length){
      setError(e.target.name, {
        type: "manual",
        message: "Please Enter Valid First Name",
      });
      setFNameError(true);
      setDisable(true);
    }
    else{
      if (e.target.value.trim() == "" ||  e.target.value.trim().length < 3){
        setError(e.target.name, {
          type: "manual",
          message: "Please Enter First Name",
        });
        setFNameError(true);
        setDisable(true);
        return false;
      } else {
        setFNameError(false);
        setDisable(false);
      }
    }
};

const LbogusCheck = (e) =>{
  const foundSwears = swear.filter(word => e.target.value.toLowerCase().includes(word.toLowerCase()));
  if(foundSwears.length){
      setError(e.target.name, {
        type: "manual",
        message: "Please Enter Valid Last Name",
      });
      setLNameError(true);
      setDisable(true);
  }
  else{
    if (e.target.value.trim() == "" ||  e.target.value.trim().length < 3){
      setError(e.target.name, {
        type: "manual",
        message: "Please Enter Last Name",
      });
      setLNameError(true);
      setDisable(true);
      return false;
    } else {
      setLNameError(false);
      setDisable(false);
    }
  }
};

  const form = splitForm.current;
  return (
    <>
      <div id="slide6" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div className="slide-9">
          <h6 className="fw-bold mt-3">
            Who should we personalise this quote for?
          </h6>
            <SelectBox
              className="form-select mb-2"
              OptionValue={Salutation}
              name="lstSalutation"
              myRef={validation({ required: "Please Select Title" })}
              validationMsg={
                validationMsg.lstSalutation &&
                validationMsg.lstSalutation.message
              }
            ></SelectBox>
            <TextField
              type="text"
              className="w-100 form-control mb-2"
              placeholder="First Name"
              name="txtFName"
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              onChange={e => setText(e.target.value) } 
              onBlur={FbogusCheck}
              validationMsg={
                validationMsg.txtFName && validationMsg.txtFName.message
              }
            ></TextField>
            <TextField
              type="text"
              className="w-100 form-control mb-2"
              placeholder="Last Name"
              name="txtLName"
              validation={validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
              onBlur={LbogusCheck}
              validationMsg={
                validationMsg.txtLName && validationMsg.txtLName.message
              }
            ></TextField>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <input
            type="button"
            id="Question_6"
            name="Question_6"
            disabled={disable}
            onClick={validateSlide}
            className="btn continue-btn fw-bold w-100  py-3 text-light next-clk"
            value="Continue >>"
          />
          <div id="slide5" className={className}>
            <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
              <img src="/assets/img/arrow.png" alt="" />{" "}
              <span
                id="back04"
                className="back04"
                children="<< Previous"
                onClick={backClick}
              >
                Previous
              </span>
            </div>
          </div>
          </div>
        </div>
    </>
  );
};
export default PersonalDetails;
