import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ProgressBar from "../../Layouts/ProgressBar";
import GTMDataLayer from "../../Layouts/GTMDataLayer";

const BasicQuestionaireTwo = ({
  className,
  slideChange,
  validation,
  backClick,
  value,
  progress
}) => {
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value == 5) {
      setCheckedQuestionThree("checked");
      setCheckedQuestionFour("unchecked");
    }
    if (e.target.value == 6) {
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("checked");
    }
    
    GTMDataLayer({
      question: 'Are you currently married?', 
      answer: e.target.title
    });
    slideChange(e);
  };
  return (
    <>
      <div id="slide2" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div className="row m-0">
          <div className="slide-3">
                <h6 className="fw-bold mt-3">
                  Are you currently married?
                </h6>
                <RadioButton
                  value="5"
                  name="question_2"
                  className="radio"
                  labelName="Yes"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionThree}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="6"
                  name="question_2"
                  className="radio"
                  labelName="No"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionFour}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
            <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
              <img src="/assets/img/arrow.png" alt="" />{" "}
              <span
                id="back01"
                className="back01"
                children="<< Previous"
                onClick={backClick}
              >
               Previous
              </span>
            </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default BasicQuestionaireTwo;
