import React, { useState } from "react";
import "../../../assets/AMCS_ADV_1/css/AMCS_ADV_1.scss";
import AdvHeader from '../../Includes/Layouts/AMCS_ADV_1/AdvHeader';
import AdvFooter from '../../Includes/Layouts/AMCS_ADV_1/AdvFooter';
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import { Months } from "../../../Constants/Constants"

const AMCS_ADV_1 = () => {
  const [showCookie, setshowCookie] = useState('show');
  var now = new Date();
  const year = now.getFullYear()
  const date = now.getDate()
  const monthIndex = now.getMonth();
  const monthName = Months[monthIndex];
  const formatted = ` ${monthName} ${date}, ${year}`;
  const [showTime, setShowTime] = useState(formatted);
  var cookieName = "CookiesPolicyHide";
  const cookieClose = (e) => {
    e.preventDefault();
    setshowCookie('hide');
    if (cookieName != null) {
      var d = new Date();
      var cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; ";
      setshowCookie('hide');
    }
  }
  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="AMCS_ADV_1" />
      <div className="AMCS_ADV_1">
        <AdvHeader />
        <section className="banner_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="adv_txt"><span className="span_1">Advertorial •</span>
                  <span className="span_2">Lorem Ipsum</span>
                •
                <span className="span_3">{showTime}</span>
                </p>
                <h1>Lorem Ipsum is simply dummy text of the printing.… </h1>
                <AnchorAdv dataId="PARA_IMAGE_1"><img src="/assets/AMCS_ADV_1/img/bg.jpg" alt="" className="banner" /></AnchorAdv>
                <p className="link_txt"><strong><AnchorAdv dataId="PARA_TEXT_LINKS_1">Lorem Ipsum is simply dummy text of the printing.</AnchorAdv></strong></p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                <ul>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                </ul>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
              </div>
            </div>
          </div>
        </section>
        <section className="midpage_cta">
          <div className="container">
            <div className="col-lg-12 text-center">
              <h2>Lorem Ipsum is simply dummy text</h2>
              <AnchorAdv dataId="FLAG_IMAGE_1">
                <div className="age_btnbg text-center">
                  <a href="#" className="btn"> &lt; 64</a>
                  <a href="#" className="btn">64</a>
                  <a href="#" className="btn">65-70</a>
                  <a href="#" className="btn">71-75</a>
                  <a href="#" className="btn">76-80</a>
                  <a href="#" className="btn">81-85</a>
                </div>
              </AnchorAdv>
            </div>
          </div>
        </section>
        <section className="txt_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="midpage_cta">
          <div className="container">
            <div className="col-lg-12 text-center">
              <h2>Lorem Ipsum is simply dummy text</h2>
              <AnchorAdv dataId="FLAG_IMAGE_1">
                <div className="age_btnbg text-center">
                  <a href="#" className="btn">&lt; 64</a>
                  <a href="#" className="btn">64</a>
                  <a href="#" className="btn">65-70</a>
                  <a href="#" className="btn">71-75</a>
                  <a href="#" className="btn">76-80</a>
                  <a href="#" className="btn">81-85</a>
                </div>
              </AnchorAdv>
              <div className="col-12 text-center d-block d-sm-none d-md-none d-lg-none">
                <div className="hvr-grow-rotate">
                  <a href="#" className="btn btm_btn">
                    <img src="dist/img/fade_tick.png" alt="" />
                    GET STARTED NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="btm_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-7 col-sm-12 col-12 text-left">
                <p>Lorem Ipsum is simply dummy text</p>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12 col-12 text-center d-none d-sm-block d-md-block d-lg-block">
                <div className="hvr-grow-rotate">
                  <AnchorAdv dataId="FLAG_IMAGE_1">
                    <a href="#" className="btn btm_btn">
                      <img src="/assets/AMCS_ADV_1/img/fade_tick.png" alt="" />
                    GET STARTED NOW
                  </a>
                  </AnchorAdv>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AdvFooter />
        <div className={` cookie ${showCookie}`} >
          <div className="row">
            <div className="col-lg-12 text-center">
              <p>This website uses cookies to ensure you get the best experience on our website.&nbsp; <a className="cl" onClick={cookieClose}> Close</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AMCS_ADV_1;
