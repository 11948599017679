import React, { useReducer, useState } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import Anchor from "../../../UI/Anchor";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import ProgressBar from "../../Layouts/ProgressBar";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  value
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      slideChange(e);
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  return (
    <>
      <div id="slide5" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div className="slide-10">
          <HiddenField
            name="pCode_EntryType"
            inputId="pCode_EntryType"
            inputValue={postCodeEntry}
          />
            <h6 className="w-bold mt-3">
              Great! Let's confirm that you live in qualifying postcode
            </h6>
                <AddressTextField
                  type="text"
                  placeholder="Postcode"
                  name="txtPostCode"
                  id="txtPostCode"
                  className="w-100 form-control mb-2"
                  dataId="txtPostCode"
                  onBlur={checkValidation}
                  autoComplete="off"
                  validation={validation({
                    required: "Please Enter Valid UK Postcode",
                    pattern: {
                      value:
                        /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                      message: "Please Enter a Valid Postcode",
                    },
                    minLength: {
                      value: 5,
                      message: "Invalid UK Postcode",
                    },
                  })}
                />
                <i className="validate secondcheck" id="validatePostcode"></i>
                {validationMsg.txtPostCode && (
                  <span className="error_msg " id="errorPostcode">
                    {validationMsg.txtPostCode.message}
                  </span>
                )}
          <div
            className="col-lg-12 col-md-12 col-sm-12 bord text-center"
            style={state.lookUp ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                style={{ float: "none" }}
                name="next"
                className="btn continue-btn fw-bold w-100  py-3 text-light next-clk"
                value="Lookup Address"
                btnType="button"
                onClick={lookupValidation}
              />
            </div>
          </div>
          <div
            className={`form-group`}
            id="currentAddressCollapse"
            style={
              state.checkSelect ? { display: "block" } : { display: "none" }
            }
          >
              <AddressSelectBox
                className="form-select mb-2"
                OptionValue={state.getAddress}
                name="address1"
                id="address1"
                onChange={getValue}
                myRef={validation({ required: "Please select address" })}
                validationMsg={
                  validationMsg.address1 && validationMsg.address1.message
                }
              />
          </div>
          <div
            className="col-12 text-center"
            style={
              state.getDetails.length === 0
                ? { display: "block" }
                : { display: "none" }
            }
          ></div>
          <div className={`${state.showManualAddress}`}>
              <AddressTextField
                type="text"
                placeholder="Address Line 1 *"
                name="txtHouseNumber"
                id="txtHouseNumber"
                className="w-100 form-control mb-2"
                dataId="txtHouseNumber"
                autoComplete="off"
                readonly={true}
                validation={validation({
                  required: "Please Enter Address Line 1",
                })}
                validationMsg={
                  validationMsg.txtHouseNumber &&
                  validationMsg.txtHouseNumber.message
                }
                value={state.getDetails.line_1}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
              <AddressTextField
                type="text"
                placeholder="Address Line 2"
                name="txtAddress3"
                id="txtAddress3"
                className="w-100 form-control mb-2"
                dataId="txtAddress3"
                autoComplete="off"
                readonly={true}
                validation={validation()}
                validationMsg=""
                value={state.getDetails.line_2}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
              <AddressTextField
                type="text"
                placeholder="County"
                name="txtCounty"
                id="txtCounty"
                className="w-100 form-control mb-2"
                dataId="txtCounty"
                autoComplete="off"
                readonly={true}
                validation={validation()}
                validationMsg=""
                value={state.getDetails.county}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
              <AddressTextField
                type="text"
                placeholder="Town *"
                name="txtTown"
                id="txtTown"
                className="w-100 form-control mb-2"
                dataId="txtTown"
                autoComplete="off"
                readonly={true}
                validation={validation({ required: "Please Enter Town" })}
                validationMsg={
                  validationMsg.txtTown && validationMsg.txtTown.message
                }
                value={state.getDetails.town}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
              <AddressTextField
                type="text"
                placeholder="Country *"
                name="txtCountry"
                id="txtCountry"
                className="w-100 form-control mb-2"
                dataId="txtCountry"
                autoComplete="off"
                readonly={true}
                validation={validation({ required: "Please Enter Country" })}
                validationMsg={
                  validationMsg.txtCountry && validationMsg.txtCountry.message
                }
                value={state.getDetails.country}
              />
              <i
                className="validate validate_success"
                aria-hidden="true"
                style={{ display: "none" }}
              ></i>
            {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div
            className="col-lg-12 col-12 text-center p-0"
            style={state.nextClick ? { display: "block" } : { display: "none" }}
          >
            <div style={{ width: "100%" }} className="main_1">
              <InputButton
                name="postcode-next"
                className="btn continue-btn fw-bold w-100  py-3 text-light next-clk"
                id="postcode-next"
                value="Continue >>"
                btnType="button"
                onClick={addressValidation}
                style={{ float: "none" }}
              />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
            <img src="/assets/img/arrow.png" alt="" />
            <span
              id="postcode-back"
              className="postcode-back"
              children="<< Previous"
              onClick={backClick}
            >
              {" "}
              Previous
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default PostCode;
