import react, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ProgressBar from "../../Layouts/NF_V1/ProgressBar";
import GTMDataLayer from "../../Layouts/GTMDataLayer";

const BasicQuestionaireCountry = ({
  className,
  slideChange,
  validation,
  backClick,
  value,
}) => {
  const [checkedQuestion15, setCheckedQuestion15] = useState("unchecked");
  const [checkedQuestion16, setCheckedQuestion16] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value == 3) {
      setCheckedQuestion15("checked");
      setCheckedQuestion16("unchecked");
    }
    if (e.target.value == 4) {
      setCheckedQuestion15("unchecked");
      setCheckedQuestion16("checked");
    }

    GTMDataLayer({
      question: 'Do you live in England or Wales?', 
      answer: e.target.title
    });
    
    slideChange(e);
  };
  return (
    <>
      <div id="slide2" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div className="row m-0">
          <div className="slide-2">
              <h6 className="fw-bold mt-3">
                Do you live in England or Wales?
              </h6>
                <RadioButton
                  value="3"
                  name="question1_2"
                  className="radio"
                  labelName="Yes"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestion15}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="4"
                  name="question1_2"
                  className="radio"
                  labelName="No"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestion16}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
              <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
                <img src="/assets/img/arrow.png" alt="" />{" "}
                <span
                  id="back01_2"
                  className="back01_2"
                  children="<< Previous"
                  onClick={backClick}
                >
                  Previous
                </span>
              </div>
            </div>
          </div>
        </div>
</>
  );
};
export default BasicQuestionaireCountry;

