import React, {useRef, useState, useContext,useReducer,useEffect } from 'react';
import "../../assets/web-thankyou-will/css/scss/main.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { userInfo } from '../../Hooks/userInfo';
import { FormData } from "../../Utility/FormData";

const Thankyou = () => {

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getUserInfo } = userInfo();
  const [currentUuid, setCurrentUuid] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const queryuuid = queryParams.get('uuid');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const formData = JSON.parse(localStorage.getItem("formData"));
  const { setFormData } = FormData();
  const getUuid = () =>{
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if(visitorData){
        var uuid = visitorData;
    }
    else{
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem('uuid');
        var uuid = (query_uuid)? query_uuid : local_storage_uuid;
    }
    return uuid;
}

const uuid = getUuid();

   function Capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
 

  useEffect(() => {
    // const formData = JSON.parse(localStorage.getItem('formData'));
    setCurrentUuid(uuid);

    if(formData ===  undefined || formData === null || formData == ''){
        (async () => {
            const response = await getUserInfo(
                uuid
            );
            const set_formData = setFormData(
                response
            );
            let dataLength = (response.data.response).length;
            if(dataLength > 0) {
                const FirstName = (response.data.response[0].first_name) ? Capitalize(response.data.response[0].first_name) : '';
                const Lastname = (response.data.response[0].last_name) ? Capitalize(response.data.response[0].last_name) : '';
                setUserFirstName(FirstName);
                setUserLastName(Lastname);
            }
            })();
        }
    else
    {
    const FirstName = formData.txtFName ;
    const Lastname = formData.txtLName;
    setUserFirstName(FirstName);
    setUserLastName(Lastname);
    }
    }, []);

  // const formData = JSON.parse(localStorage.getItem('formData'));
  // var user_name
  // // const user_name
  // if(formData)
  // {
  //   user_name = formData.txtFName+' '+formData.txtLName;
  // }
  
  const [showPrivacy, setShowPrivacy] = useState("hide");
  const [showTerms, setShowTerms] = useState("hide");
  const dismissPop = (e) => {
    switch (e.target.id) {
      case 'Privacy': {
        setShowPrivacy("hide");
        document.body.classList.remove("modal-open");
        break;
      }
      case 'terms': {      
        setShowTerms("hide");
        document.body.classList.remove("modal-open");
        break;
      }
     
    }
  }
  localStorage.clear();

  return (
    <>
    <div className='mywillmywaythanku'>
    <header className="bg-white py-2">
         <div className="container">
            <div className="row">
               <div className="col-xl-12 col-lg-12 text-center">
                  <a href="#"> <img src="/assets/web-thankyou-will/img/thankulogo.png" alt=""/> </a>     
               </div>
              
            </div>
         </div>
      </header> 

    <section className="thankyou-content">
         <div className="container">
            <div className="row">
            
               <div className="col-lg-6 text-left mb_bg">
                  <h1 className="text-light fw-bold mb-5">Thank You, {userFirstName} {userLastName}.</h1>
                  <p className="text-light">One of our trusted advisors needs to speak with you on the phone briefly to get a bit more information.</p>
                  <p className="text-light">And don’t worry! This phone call does NOT commit you to buying anything and is simply to help you choose the best option.</p>
               </div>
               <div className="col-lg-6">
               </div>
            </div>
         </div>
      </section>
      
      <footer className="bg-white">
               <ul className="mb-0 mt-0 text-center text-light">
               <li><a className="text-light" data-toggle="modal" data-target=".bs-example-modal-lg-Privacy"  onClick={() => { setShowPrivacy("show"); document.body.classList.add("modal-open"); }}>Privacy Policy</a></li>
                  <li><a className="text-light" data-toggle="modal" data-target=".bs-example-modal-lg-terms" onClick={() => { setShowTerms("show"); document.body.classList.add("modal-open"); }}>Terms and Conditions</a></li>   
               </ul>
      </footer>

      <div className={`modal fade ${showPrivacy}`} id="Privacy" tabIndex="-1" onClick={dismissPop}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
              <h5 className="modal-title">Privacy Policy</h5>
                <button onClick={() => { setShowPrivacy("hide"); document.body.classList.remove("modal-open"); }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                        <h5>We’re making it easier for you to find out how we handle your information</h5>
                        <p>On the 25th May 2018 the General Data Protection Regulation, known as GDPR, came into effect. It gives you more control over how your data is used and how you’re contacted. The changes will also help to protect your personal data.</p>
                        <p>For you to find out what these new changes mean, we’ve created a new Privacy Notice
                            which includes details about:</p>
                        <ul className="prvcy_img">
                            <li>Your rights relating to the information we hold about you</li>
                            <li>How we keep your personal information safe</li>
                            <li>The types of personal information we collect and use</li>
                            <li>The legal basis we rely on to use your information</li>
                        </ul>
                        <a href="/assets/NF_V1/media/general-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            General Privacy Notice
                        </a>
                        <p>The General Privacy Notice is for those policyholders with either a Tax-Exempt Savings Plan, 
                            With-Profits Bond, Guaranteed Life Assurance Plan or one of our legacy policies including 
                            Care Plus, Pensions, Unit Linked Plans, National Ambulance, Permanent Care, Dental & Optical, 
                            ISAs, Term Assurance and Custom and Benefits Fund policies.
                        </p>

                        <a href="/assets/NF_V1/media/pmi-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            PMI Privacy Notice
                        </a>
                        <p>The PMI Privacy Notice is for those policyholders with either an Optimum, Your Health Fund, Healthcare or 
                            Healthguard policy.</p>

                        <a href="/assets/NF_V1/media/protection-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            Protection Privacy Notice
                        </a>
                        <p>The Protection Privacy Notice is for those policyholders with a Health Cover, Right to Health Cover or 
                            THIS Health Cover policy.</p>
                        
                        <a href="/assets/NF_V1/media/long-term-care-insurance-privacy-notice-jul22.pdf" target="_blank" className="btn btn-download2 btn-block mb-3">
                            Long Term Care Insurance Privacy Notice
                        </a>
                        <p>The Long–term Care Insurance Privacy Notice is for those policyholders with an Immediate Care Plan, a Deferred Care Plan or an 
                            Assisted Living Insurance policy.</p>
                    </div>
              <div className="modal-footer">
                <button type="button" onClick={() => { setShowPrivacy("hide"); document.body.classList.remove("modal-open"); }} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal fade ${showTerms}`} id="terms" tabIndex="-1" onClick={dismissPop}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
               <h5 className="modal-title">Terms & Conditions</h5>
                <button onClick={() => { setShowTerms("hide"); document.body.classList.remove("modal-open"); }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                        <p>These terms apply to your use of our website. You should check you’re happy to agree
                            to them before using this website and ask us to explain anything you’re unsure about.</p>
                            <h5>Definitions</h5>
                            <ul>
                                <li className="mm"><b>Terms: </b>means these website terms of use</li>
                                <li><b>We/Us/Our:  </b>means National Friendly</li>
                                <li><b>You:  </b>means the user of the website</li>
                            </ul>
                            <h5>Content, copyright & trademarks</h5>
                            <p>This site is designed to explain who we are and what services we provide.</p>
                            <p>We may need at any time to change or remove, temporarily or permanently, the website or any part of it without notice.</p>
                            <p>All copyright, trademarks and all other intellectual property rights in all material or content provided as part of the website belong to us or our partners and no permission is given in respect of their use.</p>
                            <p>You may download, store and print the content on this site for your personal use only. You are not permitted to publish, re-transmit, re-distribute or otherwise reproduce any of the content that appears on any page of this site, and are prohibited from using content for a commercial or illegal purpose.</p>
                            <h5>Third party links</h5>
                            <p>We provide third party links to other websites for your convenience, but as we do not own or control them we cannot be responsible for their availability or functionality.</p>
                            <h5>Governing law</h5>
                            <p>These terms shall be governed by and construed in accordance with English law and the English courts shall have jurisdiction over any disputes.</p>
                    </div>
              <div className="modal-footer">
                <button type="button" onClick={() => { setShowTerms("hide"); document.body.classList.remove("modal-open"); }} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>




      </>
      )
    }
    
    export default Thankyou;