import React,{useState} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const Testimonial = ({onClick}) =>{
    const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
    return(
        <>
        <section className="testimonial">
            <div className="container">
                <div className="row">                    
                    <div className="testi-slide col-lg-12 col-12 ">
                        <div className="testimonial-slider">
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={800}
                                keyBoardControl={true}
                                transitionDuration={1000}
                                containerClass="carousel-container"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                activeIndex={index}
                                onSelect={handleSelect}
                                arrows={false}
                            >
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>thank you so much amazing service and…</h4>
                                    <p>thank you so much amazing service and always kept up to date with progress</p>
                                    <p className="time">nicola duckers - 20 hours ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Would highly recommend- nothing negative to say</h4>
                                    <p>I put off engaging for a while but when I did finally complete the paperwork everything went so smoothly.</p>
                                    <p className="time">Sharon Oakey - 2 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Excellent service</h4>
                                    <p>Excellent service, great communication all the way through, very fast service</p>
                                    <p className="time">Tracy Olsson - 3 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Excellent service</h4>
                                    <p>Excellent service, great communication kept me in the loop everytime their was a change highly recommend</p>
                                    <p className="time">Amanda Reid - 4 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>So helpful and straight forward</h4>
                                    <p>So helpful and straight forward. Updates as soon as they receive them.
                                        Response time to queries are sufficient.
                                    </p>
                                    <p className="time">Victoria Shaw - 4 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>I saw an advert by Allegient saying I…</h4>
                                    <p>I saw an advert by Allegient saying I may be entitled to compensation, so I applied,</p>
                                    <p className="time">Colin Herdman - 5 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Brilliant service"</h4>
                                    <p>Brilliant service , kept me well informed every step of the way ! Thanks so much ...</p>
                                    <p className="time">JP - 7 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Keep you informed</h4>
                                    <p>I would highly recommend this company to liaise with lenders on your behalf - you just sit back and let them do all the work...</p>
                                    <p className="time">Rebecca Gladwin - Updated 7 days ago</p>
                                </Link>
                            </div>
                            <div className="testimonial-cell col-lg-12 col-12 sub-test">
                                <Link onClick={onClick} target="_blank">
                                    <h3> <img src="/assets/NF_V1/img/rating-star.png" alt=""/> &nbsp;</h3>
                                    <h4>Excellent service I Didn't have to do…</h4>
                                    <p>Excellent service I Didn't have to do that much they done all the work for me and we got good result at the end. Thank you...</p>
                                    <p className="time">customer - Dec 10, 2021</p>
                                </Link>
                            </div>
                            </Carousel>
                        </div>
                    </div>

                    <div className=" col-lg-12 col-12 t-mob text-center">
                    <Link onClick={onClick} target="_blank">
                        <p>Rated 4.9 / 5 based on <u>164 reviews</u>. Showing our 4 & 5 star reviews</p>
                        <div className="col-lg-12 col-md-8 col-sm-8 col-12 text-left p-0 trust-sec">
                          <img src="/assets/NF_V1/img/trustp.png" className="trustpi" alt="" title="" />
                        </div>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Testimonial;