import react, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
// import ProgressBar from "../../Layouts/ProgressBar";
import GTMDataLayer from "../../Layouts/GTMDataLayer";

const BasicQuestionaireFour = ({
  className,
  slideChange,
  validation,
  backClick,
  value
}) => {
  const [checkedQuestionSeven, setCheckedQuestionSeven] = useState("unchecked");
  const [checkedQuestionEight, setCheckedQuestionEight] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value == 7) {
      setCheckedQuestionSeven("checked");
      setCheckedQuestionEight("unchecked");
    }
    if (e.target.value == 8) {
      setCheckedQuestionSeven("unchecked");
      setCheckedQuestionEight("checked");
    }

    GTMDataLayer({
      question: 'Are you a homeowner?', 
      answer: e.target.title
    });
    
    slideChange(e);
   // progress(value);
  };
  return (
    <>
      <div id="slide4" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div className="row m-0">
          <div className="slide-2">
              <h6 className="fw-bold mt-3">Are you a homeowner?</h6>
                <RadioButton
                  value="7"
                  name="question_4"
                  className="radio"
                  labelName="Yes, I own my home"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionSeven}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="8"
                  name="question_4"
                  className="radio"
                  labelName="No, I rent"
                  labelClassName={`chk-btn text-light next-clk ${checkedQuestionEight}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
            <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
              <img src="/assets/img/arrow.png" alt="" />{" "}
              <span
                id="back03"
                className="back03"
                children="<< Previous"
                onClick={backClick}
              >
                Previous
              </span>
            </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default BasicQuestionaireFour;
