import React, { useState } from "react";
import ProgressBar from "../../Layouts/ProgressBar";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import GTMDataLayer from "../GTMDataLayer";
import Button from "../../../UI/Button";

const DobDeatils = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  splitForm,
  backClick,
  value,
  clearErrors,
}) => {
  const form = splitForm.current;
  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    lstDobMonthResult = await trigger("DobMonth");
    console.log(lstDobMonthResult);
    if (lstDobMonthResult === false) {
      document.getElementById("validateDobMonth").classList.add("date-error");
      document.getElementById("validateDobMonth").classList.remove("success");
    }

    if (lstDobMonthResult) {
      console.log(lstDobMonthResult);
      document.getElementById("validateDobMonth").classList.add("success");
      document
        .getElementById("validateDobMonth")
        .classList.remove("date-error");

      lstDobDayResult = await trigger("DobDay");
      console.log(lstDobDayResult);
    } else {
      return false;
    }
    if (lstDobDayResult) {
      document.getElementById("validateDobDay").classList.add("success");
      document.getElementById("validateDobDay").classList.remove("date-error");

      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("validateDobDay").classList.add("date-error");
      document.getElementById("validateDobDay").classList.remove("success");
      console.log("else");
      return false;
    }

    if (lstDobYearResult) {
      document.getElementById("validateDobYear").classList.add("success");
      document.getElementById("validateDobYear").classList.remove("date-error");
    } else {
      document.getElementById("validateDobYear").classList.add("date-error");
      document.getElementById("validateDobYear").classList.remove("success");
      return false;
    }

    if (!lstDobDayResult || !lstDobMonthResult || !lstDobYearResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      GTMDataLayer({
        question: "Dob",
        answer: "completed",
      });
      slideChange(e);
    } else {
      return false;
    }
  };

  return (
    <>
      <div id="slide5" className={`${className}`}>
        {/* <ProgressBar value={value} /> */}
        <div class="slide-8">
          <h6 className="fw-bold mt-3">
            Enter Your Date of Birth to See What Plans You Qualify For
          </h6>
          <div class="row m-0">
            <DateOfBirth
              textDob=""
              validation={validation}
              validationMsg={validationMsg}
              clearErrors={clearErrors}
            />
          </div>
            <div class="col-xl-12 col-lg-12 text-center">
                <Button
                  type="button"
                  id="Question_5"
                  name="Question_5"
                  className="btn continue-btn fw-bold w-100 py-3 text-light next-clk"
                  value="Continue >>"
                  buttonText="Continue >>"
                  onClick={basicDetailsValidation}
                />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 text-center mt-1 back_btn">
            <img src="/assets/img/arrow.png" alt="" />{" "}
            <span
              id="back05"
              className="back05"
              children="<< Previous"
              onClick={backClick}
            >
              Previous
            </span>
          </div>
      </div>
    </>
  );
};
export default DobDeatils;
