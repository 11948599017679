import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import NF_V1 from "./Components/Pages/Splits/NF_V1";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/Questionnaire";
import Followup from "./Components/Pages/Splits/Followup";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import NotFound from "./Components/Pages/NotFound";
import AMCS_ADV_1 from "./Components/Pages/Advertorials/AMCS_ADV_1";
import Thankyou from "./Components/Pages/Thankyou";
import NF_V2 from "./Components/Pages/Splits/NF_V2";


const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={["/NF_V1"]} component={NF_V1} />
              <Route exact path={["/NF_V2"]} component={NF_V2} />
              <Route exact path={"/questionnaire"} component={Questionnaire} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={["/thankyou"]} component={Thankyou} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/AMCS_ADV_1"]} component={AMCS_ADV_1} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
